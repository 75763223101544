import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Coming Soon" />

    <div style={{
      display:"flex",width:"100%",height:"100vh",alignItems:'center',justifyContent:"center"
    }}>
      <div style={{padding:"0 2%"}}>
        <StaticImage
          src="../images/logo.png"
          // layout="fullWidth"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="talk works"
          style={{ marginBottom: `1.45rem` }}
        />

        <p className="coming-soon">coming soon</p>
      </div>

    </div>
  </Layout>
)

export default IndexPage
